<template>
  <div class="sidebar">
    <div class="dashboard">
      <h1>Thema's</h1>
      <p>Hieronder staan de verschillende thema's. Deze kunnen ook in de balk bovenaan de pagina worden geselecteerd bij het bijbehorende icoontje. De meeste grafieken zijn zowel via de levenspadfases te vinden als via de factsheets.</p>
      <br>
        <li><router-link class="item" to="/mijn-regio">Mijn regio</router-link></li>
        <li><router-link class="item" to="/Factsheet/1">Thema Achtergrondkenmerken</router-link></li>
        <li><router-link class="item" to="/Factsheet/2">Thema Langdurige Zorg</router-link></li>
        <li><router-link class="item" to="/Factsheet/3">Thema Ziekenhuisopnames</router-link></li>
        <li><router-link class="item" to="/Factsheet/4">Thema Maatschappelijke Ondersteuning</router-link></li>
        <li><router-link class="item" to="/Factsheet/5">Thema Medicatie</router-link></li>
        <li><router-link class="item" to="/Factsheet/6">Thema Huisarts(enpost)</router-link></li>
        <li><router-link class="item" to="/Factsheet/7">Thema Zorg door de tijd</router-link></li>
        <li><router-link class="item" to="/Factsheet/8">Thema Mantelzorg</router-link></li>
    </div>
  </div>
</template>

<script>


export default {
  name: 'App',
  components: { },
  methods: {
  }
}

</script>

<style>
.item {
  color: var(--color-1);
}
</style>
